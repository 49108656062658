<template>
  <div>
    <FilterTrasferimentiLista
      @getTrasfList="searchTrasfList"
      @resetFilters="resetFilters"
    />
    <TableTrasferimentiLista
      @getTrasfList="getTrasfList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

import FilterTrasferimentiLista from "@/components/components-fit/tesserati/trasferimenti/FilterTrasferimentiLista.vue";
import TableTrasferimentiLista from "@/components/components-fit/tesserati/trasferimenti/TableTrasferimentiLista.vue";

export default defineComponent({
  name: "TrasferimentiList",
  components: {
    FilterTrasferimentiLista,
    TableTrasferimentiLista,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Trasferimenti / Svincoli", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipTrasf);
    const fetchRows = computed(() => store.getters.fetchRowsTrasf);
    const sortColumn = computed(() => store.getters.sortColumnTrasf);
    const sortOrder = computed(() => store.getters.sortOrderTrasf);

    const id_Societa_Provenienza = computed(
      () => store.getters.idSocProvenienzaTrasf
    );
    const id_Societa_Destinazione = computed(
      () => store.getters.idSocDestinazioneTrasf
    );
    const data_Da = computed(() => store.getters.dataDaTrasf);
    const data_A = computed(() => store.getters.dataATrasf);
    const cognome = computed(() => store.getters.cognomeTrasf);
    const nome = computed(() => store.getters.nomeTrasf);
    const matricola = computed(() => store.getters.matricolaTrasf);
    const id_Stato = computed(() => store.getters.statoTrasf);
    const oneroso = computed(() => store.getters.onerosoTrasf);

    const tutteTrasfList = computed(() => store.getters.tutteTrasfList);

    const getTrasfList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          anno: tutteTrasfList.value ? 10000 : anno.value,
          id_Societa_Provenienza: id_Societa_Provenienza.value,
          id_Societa_Destinazione: id_Societa_Destinazione.value,
          data_Da: data_Da.value,
          data_A: data_A.value,
          cognome: trimInput(cognome.value),
          nome: trimInput(nome.value),
          matricola: matricola.value,
          id_Stato: id_Stato.value,
          oneroso: oneroso.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.TRASFERIMENTI_LIST,
        itemName: "trasferimenti_list",
      });
    };
    getTrasfList();

    const resetFilters = () => {
      store.commit("resetFiltersTrasf");
      getTrasfList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedtrasferimenti_list")
    );
    const searchTrasfList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipTrasf");
      getTrasfList();
    };

    return {
      getTrasfList,
      resetFilters,
      searchTrasfList,
      tutteTrasfList,
    };
  },
});
</script>
