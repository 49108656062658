import services from "../axios/dbManag";

export const addTrasferimentoRichiesta = (richiesta) => {
  return services.dbManag
    .post("/tesseramento/trasferimenti/add", {
      ...richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const setTrasferimentoStato = (id_Richiesta, stato_Domanda) => {
  return services.dbManag
    .post("/tesseramento/trasferimenti/aggiorna/edit", {
      id_Richiesta: id_Richiesta,
      stato_Domanda: stato_Domanda,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const setTrasferimentoApprova = (id_Richiesta) => {
  return services.dbManag
    .post("/tesseramento/trasferimenti/approva", {
      id_Richiesta: id_Richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const setTrasferimentoRifiuta = (id_Richiesta) => {
  return services.dbManag
    .post("/tesseramento/trasferimenti/rifiuta", {
      id_Richiesta: id_Richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const setTrasferimentoCancella = (id_Richiesta) => {
  return services.dbManag
    .post("/tesseramento/trasferimenti/cancella", {
      id_Richiesta: id_Richiesta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
