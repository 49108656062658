<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-6">
        <div class="btn btn-light text-gray-700 fs-6 text-center">
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteLeTrasfList"
            :checked="tutteTrasfList"
          />visualizza tutte le stagioni
        </div>
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          trasferimenti di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        trasferimenti_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="trasferimenti_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsTrasf"
        @current-change="setCurrentPageTrasf"
      >
        <template v-slot:cell-tesserato="{ row: data }"
          ><div class="tab-long">
            {{ data.tesserato }}
          </div>
        </template>
        <template v-slot:cell-tipo_tessera="{ row: data }"
          >{{ data.tipo_tessera }}
        </template>
        <template v-slot:cell-societa_provenienza="{ row: data }"
          ><div class="tab-long">{{ data.societa_provenienza }}</div>
        </template>
        <template v-slot:cell-societa_destinazione="{ row: data }"
          ><div class="tab-long">{{ data.societa_destinazione }}</div>
        </template>
        <template v-slot:cell-stato="{ row: data }">
          <h1 :class="getClass(data.id_stato)">
            {{ data.stato }}
          </h1>
        </template>
        <template v-slot:cell-data_richiesta="{ row: data }"
          ><div class="text-center">
            {{ getFormatDate(data.data_richiesta) }}
          </div>
        </template>
        <template v-slot:cell-oneroso="{ row: data }"
          ><span v-if="data.oneroso">Si</span
          ><span v-if="!data.oneroso">No</span>
        </template>
        <template v-slot:cell-svincolo="{ row: data }"
          ><span v-if="data.svincolo">Si</span
          ><span v-if="!data.svincolo">No</span>
        </template>
        <template v-slot:cell-articolo="{ row: data }"
          >{{ data.articolo }}
        </template>
        <template v-slot:cell-note="{ row: data }">{{ data.note }} </template>
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group" v-if="data.id_stato <= 2">
            <button
              v-if="!readOnly"
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <a
                href="#"
                @click.prevent="setAzioni(data.id_richiesta, 2)"
                v-if="data.id_stato == 1"
              >
                <li role="presentation" class="navi-item" v-if="!readOnly">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check-square text-success fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Approva </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="data.id_stato == 1"
                @click.prevent="setAzioni(data.id_richiesta, 3)"
              >
                <li role="presentation" class="navi-item" v-if="!readOnly">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-x-square text-warning fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Rifiuta </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                @click.prevent="setAzioni(data.id_richiesta, 4)"
                v-if="data.id_stato == 1 || data.id_stato == 2"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-trash text-danger fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Elimina </span>
                    </div>
                  </div>
                </li>
              </a>
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import isEnabled from "@/composables/isEnabled.js";
import {
  setTrasferimentoApprova,
  setTrasferimentoRifiuta,
  setTrasferimentoCancella,
} from "@/requests/tesseratiTrasferimentiRichieste";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// componente spinner
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "TableTrasferimentiLista",
  components: { Datatable, Loading },
  emits: ["getTrasfList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const setFetchRowsTrasf = (e) => {
      store.commit("setFetchRowsTrasf", e);
      emit("getTrasfList");
    };
    const setCurrentPageTrasf = (page) => {
      store.commit("setCurrentPageTrasf", page);
      emit("getTrasfList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnTrasf", columnName);
      store.commit("setSortOrderTrasf", order);
      emit("getTrasfList");
    };

    const isLoading = ref(false);
    const fullPage = ref(true);

    const setSpinnerView = (value) => {
      isLoading.value = value;
    };

    const setAzioni = (id, azione) => {
      azione = parseInt(azione);
      let message = "";
      switch (azione) {
        case 2:
          message =
            "Sei sicuro di voler approvare il trasferimento selezionato?";
          break;
        case 3:
          message =
            "Sei sicuro di voler rifiutare il trasferimento selezionato?";
          break;
        case 4:
          message =
            "Sei proprio sicuro di voler cancellare la richiesta di trasferimento selezionata?";
          break;
      }
      Swal.fire({
        title: message,
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          setSpinnerView(true);
          const responseUpdate = ref({});
          switch (azione) {
            case 2:
              responseUpdate.value = setTrasferimentoApprova(id);
              break;
            case 3:
              responseUpdate.value = setTrasferimentoRifiuta(id);
              break;
            case 4:
              responseUpdate.value = setTrasferimentoCancella(id);
              break;
          }
          responseUpdate.value
            .then((value) => {
              if (value.status == 200) {
                Swal.fire({
                  html: "Operazione completata con successo",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
                emit("getTrasfList");
              } else {
                const strMsg = ref(
                  value.data.message
                    ? value.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
                Swal.fire({
                  html: strMsg.value,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              }
              setSpinnerView(false);
            })
            .catch(() => {
              const strMsg = ref(
                "<b>Si è verificato un errore</b><br>Riprovare più tardi<br><br>"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const tableHeader = ref([
      {
        name: "Tesserato",
        key: "tesserato",
      },
      {
        name: "Tipo Tessera",
        key: "tipo_tessera",
      },
      {
        name: "Società provenienza",
        key: "societa_provenienza",
      },
      {
        name: "Società destinazione",
        key: "societa_destinazione",
      },
      {
        name: "Stato",
        key: "stato",
      },
      {
        name: "Data richiesta",
        key: "data_richiesta",
        align: "center",
      },
      {
        name: "Trasf. oneroso",
        key: "oneroso",
        sortable: false,
      },
      {
        name: "Svincolo",
        key: "svincolo",
        sortable: false,
      },
      {
        name: "Articolo",
        key: "articolo",
        sortable: false,
      },
      {
        name: "Note",
        key: "note",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const setTutteLeTrasfList = () => {
      store.commit("setTutteLeTrasfList");
      emit("getTrasfList");
    };

    return {
      getFormatDate,
      trasferimenti_list: computed(() =>
        store.getters.getStateFromName("resultstrasferimenti_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedtrasferimenti_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordtrasferimenti_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statustrasferimenti_list")
      ),

      currentPage: computed(() => store.getters.currentPageTrasf),
      rowsToSkip: computed(() => store.getters.rowsToSkipTrasf),
      fetchRows: computed(() => store.getters.fetchRowsTrasf),
      sortColumn: computed(() => store.getters.sortColumnTrasf),
      sortOrder: computed(() => store.getters.sortOrderTrasf),
      setFetchRowsTrasf,
      setCurrentPageTrasf,
      setSortOrderColumn,
      tableHeader,
      setAzioni,
      isLoading,
      fullPage,
      setSpinnerView,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      tutteTrasfList: computed(() => store.getters.tutteTrasfList),
      setTutteLeTrasfList,
    };
  },
  methods: {
    getClass(id_stato) {
      switch (id_stato) {
        case 1:
          return "badge badge-light-warning";
        case 2:
          return "badge badge-light-success";
        case 3:
          return "badge badge-light-danger";
        case 4:
          return "badge badge-light-danger";
      }
    },
  },
};
</script>

<style scoped></style>
