<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getTrasfList')">
          <div class="row">
            <div class="col-sm-3 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società di provenienza"
                  name="denominazione_societa_prov"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'societa';
                    getSocietaProv($event.target.value);
                    focusInput = 1;
                  "
                  :value="societaProvenienzaTrasf"
                  autocomplete="off"
                />
                <svg
                  @click="setIdSocProvenienzaTrasf({ name: null, value: null })"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa'"
                :list="societa"
                @hideList="
                  inputFocus = null;
                  resetSocieta;
                "
                @selectedSocieta="
                  resetSocietaProv();
                  setIdSocProvenienzaTrasf($event);
                  inputFocus = null;
                "
              ></ListSocieta>
            </div>
            <div class="col-sm-3 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società di destinazione"
                  name="denominazione_societa_dest"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'societa_destinazione';
                    getSocieta($event.target.value);
                  "
                  :value="societaDestinazioneTrasf"
                  autocomplete="off"
                />
                <svg
                  @click="
                    setIdSocDestinazioneTrasf({ name: null, value: null })
                  "
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa_destinazione'"
                :list="societa"
                @hideList="resetSocieta"
                @selectedSocieta="
                  resetSocieta();
                  setIdSocDestinazioneTrasf($event);
                "
              ></ListSocieta>
            </div>
            <div class="col-sm-3 filter">
              <Datepicker
                v-model="dataDaTrasf"
                @update:modelValue="setDataDaTrasf($event)"
                placeholder="Data da"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                autoApply
              />
            </div>
            <div class="col-sm-3">
              <Datepicker
                v-model="dataATrasf"
                @update:modelValue="setDataATrasf($event)"
                placeholder="Data a"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                autoApply
              />
            </div>
          </div>

          <div class="text-center mt-5">
            <span
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFilterTrasferimenti"
              aria-expanded="false"
              aria-controls="collapseExample"
              class="badge rounded-pill bg-light text-gray-600"
              @click="isActive = !isActive"
              >Filtri avanzati
              <i
                :class="{
                  'fas fa-angle-up fs-6 text-gray-600': isActive,
                  'fas fa-angle-down fs-6 text-gray-600': !isActive,
                }"
              >
              </i
            ></span>
            <button
              v-if="!isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getTrasfList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              v-if="!isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>

          <div class="collapse" id="collapseFilterTrasferimenti">
            <div class="bg-light rounded-3">
              <div class="row mt-4 pt-4 ps-2 pe-2">
                <div class="col-sm-4 filter">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Cognome"
                    aria-label=""
                    :value="cognomeTrasf"
                    @input="setCognomeTrasf"
                  />
                </div>
                <div class="col-sm-4 filter">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nome"
                    aria-label=""
                    :value="nomeTrasf"
                    @input="setNomeTrasf"
                  />
                </div>

                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Matricola"
                    aria-label=""
                    :value="matricolaTrasf"
                    @input="setMatricolaTrasf"
                  />
                </div>
              </div>
              <div class="row pb-4 ps-2 pe-2">
                <div class="col-sm-4 pt-4">
                  <SelectInput
                    :options="trasferimenti_stato"
                    name="trasferimenti_stato"
                    placeholder="Stato"
                    :value="statoTrasf"
                    @changeSelect="setStatoTrasf($event)"
                  />
                </div>
                <div class="col-sm-4">
                  <div class="pt-4">
                    <SelectInput
                      :options="trueFalseLookup"
                      name="trueFalseLookup"
                      placeholder="Oneroso"
                      :value="onerosoTrasf"
                      @changeSelect="setOnerosoTrasf($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-4">
              <button
                v-if="isActive"
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="badge rounded-pill bg-light text-gray-600 ms-4"
                @click="$emit('getTrasfList')"
                :disabled="!loaded"
              >
                Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
              </button>
              <span
                v-if="isActive"
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="badge rounded-pill bg-light text-gray-600 ms-4"
                @click="$emit('resetFilters')"
              >
                <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
              ></span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import ListSocieta from "../../utility/ListSocieta.vue";
import { useFormatDateYMD, format } from "@/composables/formatDate";

export default defineComponent({
  name: "filtri-trasferimenti-lista",
  emits: ["resetFilters", "getTrasfList"],
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
  },
  components: {
    ListSocieta,
    SelectInput,
    Datepicker,
  },
  setup(props, { emit }) {
    const focusInput = ref(0);
    const store = useStore();

    const idSocProvenienzaTrasf = computed(
      () => store.getters.idSocProvenienzaTrasf
    );
    const idSocDestinazioneTrasf = computed(
      () => store.getters.idSocDestinazioneTrasf
    );
    const dataDaTrasf = computed(() => store.getters.dataDaTrasf);
    const dataATrasf = computed(() => store.getters.dataATrasf);
    const nomeTrasf = computed(() => store.getters.nomeTrasf);
    const cognomeTrasf = computed(() => store.getters.cognomeTrasf);
    const matricolaTrasf = computed(() => store.getters.matricolaTrasf);
    const statoTrasf = computed(() => store.getters.statoTrasf);
    const onerosoTrasf = computed(() => store.getters.onerosoTrasf);
    const societaProvenienzaTrasf = computed(
      () => store.getters.societaProvenienzaTrasf
    );
    const societaDestinazioneTrasf = computed(
      () => store.getters.societaDestinazioneTrasf
    );

    const setIdSocProvenienzaTrasf = ({ value, name }) => {
      store.commit("setIdSocProvenienzaTrasf", value);
      store.commit("setSocietaProvenienzaTrasf", name);
    };

    const setIdSocDestinazioneTrasf = ({ value, name }) => {
      store.commit("setIdSocDestinazioneTrasf", value);
      store.commit("setSocietaDestinazioneTrasf", name);
    };

    const setDataDaTrasf = (event) => {
      store.commit("setDataDaTrasf", event);
    };

    const setDataATrasf = (event) => {
      store.commit("setDataATrasf", event);
    };

    const setNomeTrasf = (e) => {
      const string = e.target.value;
      store.commit("setNomeTrasf", string);
    };

    const setCognomeTrasf = (e) => {
      const string = e.target.value;
      store.commit("setCognomeTrasf", string);
    };

    const setMatricolaTrasf = (e) => {
      const string = e.target.value ? e.target.value : null;
      store.commit("setMatricolaTrasf", string);
    };

    const setStatoTrasf = (event) => {
      store.commit("setStatoTrasf", event);
    };

    const setOnerosoTrasf = (event) => {
      store.commit("setOnerosoTrasf", event);
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getTrasfList");
    });

    const getSocieta = (string) => {
      store.commit("setNomeSocDestinazioneTrasf", string);
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      // store.dispatch("getSocietaNomeCodAff", string);
      store.dispatch("getSocietaProv", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const getSocietaProv = (string) => {
      store.commit("setNomeSocProvenienzaTrasf", string);
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaProv", string);
    };
    const resetSocietaProv = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const inputFocus = ref(null);
    const isActive = ref(false);

    onMounted(() => {
      if (
        nomeTrasf.value ||
        cognomeTrasf.value ||
        matricolaTrasf.value ||
        statoTrasf.value ||
        onerosoTrasf.value
      ) {
        isActive.value = true;
        document
          .getElementById("collapseFilterTrasferimenti")
          .classList.add("show");
      }
    });

    return {
      inputFocus,
      focusInput,
      setIdSocProvenienzaTrasf,
      idSocProvenienzaTrasf,
      setIdSocDestinazioneTrasf,
      idSocDestinazioneTrasf,
      setDataDaTrasf,
      dataDaTrasf,
      setDataATrasf,
      dataATrasf,
      setNomeTrasf,
      nomeTrasf,
      setCognomeTrasf,
      cognomeTrasf,
      setMatricolaTrasf,
      matricolaTrasf,
      setStatoTrasf,
      statoTrasf,
      setOnerosoTrasf,
      onerosoTrasf,
      societaProvenienzaTrasf,
      societaDestinazioneTrasf,
      resetSocieta,
      getSocieta,
      getSocietaProv,
      resetSocietaProv,
      useFormatDateYMD,
      format,
      societa: computed(() => store.getters.societaNomeCodAff),
      stagione: computed(() => store.getters.stagioneSelected),
      trasferimenti_stato: [
        { id: "1", label: "Richiesta inviata" },
        { id: "2", label: "Approvato" },
        { id: "3", label: "Rifiutato" },
      ],
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
      loaded: computed(() =>
        store.getters.getStateFromName("loadedtrasferimenti_list")
      ),
    };
  },
});
</script>

<style></style>
